import React from "react";
const StudentInboxPage = () => {
  return (
    <div className="justify-center text-center">
      <h2>Student Inbox Page</h2>
    </div>
  );
};

export default StudentInboxPage;
