import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import StudentSiguup from "../components/Signup/StudentSignup";
const StudentSiguupPage = () =>{
 return(
    <>
    <Header/>
    <StudentSiguup/>
    <Footer/>
    </>
 )
}

export default StudentSiguupPage