import React from "react";
const OwnerDashboardMessages = () =>{

    return (
        <div className="justify-center text-center">
            Owner Dashboard Inbox Page
        </div>
    )

}

export default OwnerDashboardMessages;