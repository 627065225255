import React from "react";
import OwnerSignup from '../../components/Signup/OwnerSignup';
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
const StudentSiguupPage = () =>{
 return(
    <>
    <Header/>
    <OwnerSignup/>
    <Footer/>
    </>
 )
}

export default StudentSiguupPage