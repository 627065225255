import React from "react";
import  OwnerLogin from '../../components/Login/OwnerLogin';
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
const OwnerLoginPage = () =>{
 return(
    <>
    <Header/>
    <OwnerLogin/>
    <Footer/>
    </>
 )
}

export default OwnerLoginPage;