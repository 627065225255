import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import About from "../components/Route/About/About";

const AboutPage = () => {
  return (
    <>
      <Header />
      <About />
      <Footer />
    </>
  );
};
export default AboutPage;
