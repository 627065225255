import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Contact from "../components/Route/Contact/Contact";

const ContactPage = () => {
  return (
    <>
      <Header />
      <Contact />
      <Footer />
    </>
  );
};
export default ContactPage;
