import React from "react";
import  StudentLogin from '../components/Login/StudentLogin';
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
const StudentLoginPage = () =>{
 return(
    <>
    <Header/>
    <StudentLogin/>
    <Footer/>
    </>
 )
}

export default StudentLoginPage;