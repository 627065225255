import React from "react";
import  AdminLogin from '../../components/Login/AdminLogin';
const AdminLoginPage = () =>{
 return(
    <>
    <AdminLogin/>
    </>
 )
}

export default AdminLoginPage;